import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['howToHealthyDiet'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const SaveTextField = makeShortcode("SaveTextField");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "encourage-a-healthy-diet"
    }}>{`Encourage a healthy diet`}</h1>
    <h3 {...{
      "id": "how-do-i-do-this"
    }}>{`How do I do this?`}</h3>
    <ul>
      <li parentName="ul">{`Have a variety of nutritious foods readily available in the house. For example, you could keep some vegetable sticks and sliced fruit on the top shelf of the fridge (where your teenager is likely to see it!) or keep a fruit bowl with a selection of fruits your teenager likes.`}</li>
      <li parentName="ul">{`Limit the amount of 'treat' foods and drinks that are readily available. If it's just as easy for your teen to grab some chocolate as it is to find a healthier snack, they are more likely to choose the chocolate!`}</li>
      <li parentName="ul">{`Show your teenager how to have a healthy, balanced diet by doing so yourself.`}</li>
      <li parentName="ul">{`Keep treats as treats! It’s okay to have the occasional biscuit, chocolate, chips, or a soft drink; just don’t have it every day.`}</li>
    </ul>
    <h3 {...{
      "id": "activity"
    }}>{`Activity`}</h3>
    <p>{`What are some healthy foods I can encourage my teenager to eat? What could I do to increase the likelihood of them eating these foods?`}</p>
    <Grid container justify="center" spacing={2} mdxType="Grid">
  <SaveTextField id="howToHealthyDiet" variant="outlined" fullWidth={true} multiline={true} label="Insert text here" mdxType="SaveTextField" />
    </Grid>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      